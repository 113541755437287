import React, { useEffect, useState } from 'react';
import { Box, Typography, useTheme, Divider } from '@mui/material';
import SquareIcon from '@mui/icons-material/Square';
import {
  Timeline,
  TimelineItem,
  TimelineSeparator,
  TimelineConnector,
  TimelineContent,
  TimelineDot,
  timelineItemClasses,
} from '@mui/lab';

const GenericDataCard = ({ genericData }) => {
  // const [data, setData] = useState(genericData);
  const theme = useTheme();

  // useEffect(() => {
  //   if (genericData?.items[0]?.id != undefined) {
  //     setData(genericData);
  //   }
  // }, [genericData]);

  return (
    genericData &&
    genericData.items.length > 0 && ( // Verifica se genericData existe e possui items
      <Box id='container' p='1.5rem 2rem 0' minHeight='230px' mb='1rem'>
        <Typography
          variant='h3'
          sx={{
            fontSize: '20px !important',
            fontWeight: 'bold',
            color: theme.palette.primary.dark,
          }}
        >
          {genericData.title}
        </Typography>
        <Timeline
          sx={{
            [`& .${timelineItemClasses.root}:before`]: {
              flex: 0,
              padding: 0,
              marginLeft: '-16px',
              marginBottom: '1rem',
            },
          }}
        >
          {genericData.items.map((item) => (
            <TimelineItem key={item.id}>
              <TimelineSeparator>
                <TimelineDot
                  sx={{
                    backgroundColor: '#688f9d',
                  }}
                />
                <TimelineConnector
                  sx={{
                    backgroundColor: '#688f9d',
                  }}
                />
              </TimelineSeparator>
              <TimelineContent>
                <Box
                  id={`item ${item.id}`}
                  sx={{ display: 'flex', gap: '1rem' }}
                >
                  <Box
                    id='data'
                    mt='3px'
                    width={1}
                    sx={{
                      display: 'flex',
                      gap: '0.5rem',
                      flexDirection: 'column',
                    }}
                  >
                    <Box
                      id='data-header'
                      sx={{
                        display: 'flex',
                        gap: '1rem',
                      }}
                    >
                      <Typography
                        variant='h4'
                        sx={{
                          fontSize: '16px !important',
                          fontWeight: 'bold',
                          color: '#688f9d',
                          lineHeight: '24px',
                        }}
                      >
                        {item.title}
                      </Typography>
                      <Typography
                        variant='h5'
                        sx={{
                          fontSize: '15px !important',
                          fontWeight: 'bold',
                          color: '#688f9d',
                          marginLeft: 'auto',
                          lineHeight: '24px',
                        }}
                      >
                        {item.sidetitle}
                      </Typography>
                    </Box>
                    <Box
                      id='data-subHeader'
                      // ml="0.5rem"
                      sx={{
                        display: 'flex',
                        gap: '1rem',
                      }}
                    >
                      <Typography
                        variant='h5'
                        sx={{
                          fontSize: '15px !important',
                          fontWeight: 'bold',
                          color: '#688f9d',
                          lineHeight: '24px',
                        }}
                      >
                        {item.subTitle}
                      </Typography>
                    </Box>
                    <Box
                      id='data-body'
                      sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        gap: '0.3rem',
                      }}
                    >
                      {item.rows.map(
                        (row, index) =>
                          row && <Typography key={index}>{row}</Typography> // Exibe apenas se row não for null
                      )}
                    </Box>
                    <Box
                      id='data-list'
                      // ml="0.5rem"
                      sx={{
                        display: 'flex',
                        gap: '0.3rem',
                      }}
                    >
                      {item.list?.map((listItem, index) => {
                        return (
                          <Box
                            key={index}
                            sx={{
                              display: 'flex',
                              gap: '0.3rem',
                              alignItems: 'center',
                            }}
                          >
                            <Typography
                              sx={{
                                color: theme.palette.text.hint,
                                lineHeight: '24px',
                              }}
                            >
                              {listItem}
                            </Typography>
                            <SquareIcon
                              sx={{
                                color: theme.palette.text.hint,
                                transform: 'rotate(45deg)',
                                fontSize: '10px !important',
                              }}
                            />
                          </Box>
                        );
                      })}
                    </Box>
                  </Box>
                </Box>
              </TimelineContent>
            </TimelineItem>
          ))}
        </Timeline>
      </Box>
    )
  );
};

export default GenericDataCard;
