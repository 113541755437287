import {
  Box,
  Button,
  Divider,
  Grid,
  Modal,
  Tooltip,
  Typography,
  useTheme,
} from "@mui/material";
import { Close } from "@mui/icons-material";
import moment from "moment";
import { Form } from "@unform/web";
import React, { useEffect, useRef, useState } from "react";
import { useHistory } from "react-router-dom";
import AutoComplete from "../../../../components/Form/AutoComplete";
import Checkbox from "../../../../components/Form/Checkbox";
import DatePicker from "../../../../components/Form/DatePicker";
import Input from "../../../../components/Form/Input";
import Select from "../../../../components/Form/Select";
import ReactSelect from "../../../../components/Form/ReactSelect";
import AdminTitleHeader from "../../../components/AdminTitleHeader/AdminTitleHeader";
import HorizontalMenu from "../../../components/HorizonalMenu/HorizontalMenu";
import removeIconWhite from "../../../../assets/img/icons/deleteWhite.svg";
import uploadIcon from "../../../../assets/img/icons/upload.svg";
import downIcon from "../../../../assets/img/icons/downIcon.svg";
import useAlocacaoQuadros, {
  InformationIcon,
} from "../../../hooks/useAlocacaoQuadros";
import styles from "./alocacaoStyles.module.css";
import * as Yup from "yup";
import { styled } from "@material-ui/styles";
import KeycloakService from "../../../../services/KeycloakService";
import SideMenu from "../../../components/SideMenu/SideMenu";
import { setSnackbar } from "../../../../store/reducers/admin/actionCreators";
import { useDispatch } from "react-redux";
import axios from "axios";

const ModalContainer = styled(Grid)(({ theme }) => ({
  backgroundColor: "#fff",
  margin: "20vh 50vh",
  borderRadius: "6px",
  // [theme.breakpoints.up("xxl")]: {
  //   height: "825px",
  // margin: "20vh 50vh",

  // },
  // [theme.breakpoints.down("xxl")]: {
  //   height: "775px",
  // margin: "20vh 50vh",

  // },
  // [theme.breakpoints.down("xl")]: {
  //   height: "675px",
  // margin: "20vh 50vh",

  // },
  // [theme.breakpoints.down("lg")]: {
  //   height: "605px",
  // margin: "20vh 50vh",

  // },
}));

const UpdateAllocationSchema = Yup.object().shape({
  employeeId: Yup.string().required("O id do quadro é obrigatório."),
  unit: Yup.object().shape({
    name: Yup.string().required("A unidade organica é obrigatória."),
  }),
  // obs: Yup.string().required("O motivo da exonerção é obrigatório."),
  endedOn: Yup.string().test(
    "isLengthValid",
    "A data do fim das funções é obrigatória.",
    (val) => {
      return val.length > 0;
    }
  ),
  institutionOfOrigin: Yup.object().shape({
    id: Yup.string().optional(),
  }),
  /* institutionOfDestiny: Yup.string().required(
    "A entidade de destino é obrigatória."
  ), */
  /* position: Yup.object().shape({
    id: Yup.string().required("O cargo é obrigatório."),
  }), */
  /* unitDestinId: Yup.string().required("O orgão de destino obrigatório."), */
  // category: Yup.object().shape({
  //   id: Yup.string(),
  //   name: Yup.string().when("id", {
  //     is: (id) => id === null || id === undefined || id === "",
  //     then: Yup.string().required("Deve indicar uma categoria já existente"),
  //   }),
  // }),
  // changeOfJuridicalBond: Yup.object().shape({
  //   id: Yup.string().required("A mudança da relação juridica é obrigatória."),
  // }),
  // contractualRegimen: Yup.object().shape({
  //   id: Yup.string().required("O regime de contrato é obrigatório."),
  // }),
  // juridicalBond: Yup.object().shape({
  //   id: Yup.string().required("A relação juridica é obrigatória."),
  // }),
  // agentNumber: Yup.string()
  //   .required("O Nº Agente é obrigatório.")
  //   .test("isLengthValid", "O Nº de Agente tem que ter 8 dígitos.", (val) => {
  //     return val.length === 8;
  //   }),
  // ssNumber: Yup.string().required("O Nº de Seg. Social é obrigatório."),
  // cifNumber: Yup.string().required("O código de identificação é obrigatório."),
  // consultant: Yup.boolean().required("Este campo é obrigatorio"),
  startedOn: Yup.string().test(
    "isLengthValid",
    "A data do inicio das funções é obrigatória.",
    (val) => {
      return val.length > 0;
    }
  ),
});

const DestacamentoQuadros = ({ match }) => {
  const visualizeOnly = match.params.operation === "ver";
  const formRef = useRef();
  const theme = useTheme();
  const history = useHistory();
  const [userInfo, setUserInfo] = useState();
  const dispatch = useDispatch();

  const getUserInfo = async () => {
    const response = await KeycloakService.getUserInfo();
    const { preferred_username } = response;
    setUserInfo(preferred_username);
  };

  console.log("Operation type: ", match.params.operation);

  const [action, setAction] = useState("add");
  const [file, setFile] = useState({});
  const [showInfoModal, setShowInfoModal] = useState(false);
  const [horizontalMenuOption, setHorizontalMenuOption] =
    useState("alocacao_quadros");
  const [inputOptions, setInputOptions] = useState([]);
  const [orgaos, setOrgaos] = useState([]);
  const [inputValue, setSelectedInputValue] = useState(null);

  const {
    unit,
    institutionEmployees,
    currentEmployee,
    fieldsMetaData,
    fieldsMetaDataEdit,
    fixDate,
    fixAutoComplete,
    onEmployeeClicked,
    onAddValidationComplete,
    onEditValidationComplete,
    id,
    setSideTablePageIndex,
    institutionEmployeesTotalPages,
    query,
    setQuery,
    getResourceMobilityByEmployeeId,
    getResourceMobilityInfoByEmployeeId,
    clickedEmployee,
  } = useAlocacaoQuadros(
    match.params.institutionId,
    match.params.unitId,
    match.params.employeeId,
    "exonom"
  );

  useEffect(() => {
    if (match.params.employeeId) setAction("edit");

    if (!userInfo) {
      getUserInfo();
    }
  }, [match.params, userInfo]);

  const getHistoricType = (type) => {
    switch (type) {
      case "mobilidade":
        return 1;
      case "exonom":
        return 2;
      case "progresso":
        return 3;
      case "editar":
        return 4;
    }
  };

  const horizontalButtons = [
    {
      code: "alocacao_quadros",
      onClick: () => setHorizontalMenuOption("alocacao_quadros"),
    },
  ];
  const [startedOnNew, setStartedOnNew] = useState("");

  const editAllocationValidation = (withFixedValues) => {
    var values = withFixedValues;
    console.log("whithFixedValues e isso: ", values);
    console.log("Employe data: ", currentEmployee);

    UpdateAllocationSchema.validate(
      {
        endedOn: values.endedOn,
        employeeId: match.params.employeeId,
        unit: { name: match.params.unitId },
        startedOn: values.startedOn,
        position: values.position,
        institutionOfOriginId: values.institutionOfOrigin?.id,
        institutionOfDestinId: inputValue,
        startedOnNew,
      },
      {
        abortEarly: false,
      }
    )
      .then((submitData) => {
        console.log("Form Data: ", submitData);

        // let categoryId;
        // if (submitData.category.id) {
        //   categoryId = submitData.category.id;
        // } else {
        //   categoryId = submitData.category;
        // }
        if (Number(values.changeOfJuridicalBondId) === currentEmployee.changeOfJuridicalBond.id) {
          dispatch(
            setSnackbar({
              snackbarIsOpen: true,
              snackbarMessage: "Não é possível salvar: a Mudança de Relação Jurídica atual e a nova são idênticas.",
              snackbarType: 'warning',
            })
          );
          return;
        }

        axios
          .put(
            `${process.env.REACT_APP_API_URL}/resource-mobility/transfer`,
            {
              id: currentEmployee.id ?? null,
              employeeId: currentEmployee.employee.id ?? null,
              unitId: match.params.unitId,
              startedOn: submitData.startedOn,
              startedOnNew,
              endedOn: submitData.endedOn,
              categoryId: currentEmployee.category.id,
              responsavel: userInfo,
              historicTypeId: 6,
              agentNumber: currentEmployee.agentNumber ?? null,
              cifNumber: currentEmployee.cifNumber ?? null,
              ssNumber: currentEmployee.ssNumber ?? null,
              consultant: currentEmployee.consultant,
              changeOfJuridicalBondId: currentEmployee.changeOfJuridicalBond.id,
              contractualRegimenId: currentEmployee.contractualRegimen.id,
              juridicalBondId: currentEmployee.juridicalBond.id,
              positionId: submitData.position?.id,
              institutionOfOriginId: currentEmployee?.institutionOfOrigin?.id,
              institutionOfDestinId: submitData.institutionOfDestinId,
              showNotification: true,
              onSuccessMessage: "Destacamento feito com sucesso",
              details: "Transferindo",
            },
            {
              headers: {
                Authorization: `Bearer ${KeycloakService.getToken()}`,
              },
            }
          )
          .then((response) => {
            if (!response.data) return;
            dispatch(
              setSnackbar({
                snackbarIsOpen: true,
                snackbarMessage: "Destacamento feito com sucesso",
                snackbarType: "success",
              })
            );
            history.goBack();
            // history.push(`/admin/instituicoes/${institutionId}/orgaos/${unitId}`);
          })
          .catch((error) => {
            console.log(error);
            let messageError = "Houve um erro interno. Por favor, tente novamente.";

            if (error.response) {
              const details = error.response?.data?.details;
              const detailMessages = details ? details.map(detail => `- ${detail.message}`).join("\n") : "Nenhum detalhe adicional disponível.";

              messageError = `${error.response?.data?.message}\n\nDetalhes do erro:\n${detailMessages}`;
            }
            dispatch(
              setSnackbar({
                snackbarIsOpen: true,
                snackbarMessage: messageError,
                snackbarType: 'error',
              })
            );
            /* dispatch(setIsLoading(false)); */
          });
        formRef.current.setErrors({});
      })
      .catch((error) => {
        console.error(error)
        let messageError = "Por favor, preencha todos os campos obrigatórios.";
        let detailMessages
        const errorMessages = {};
        if (error instanceof Yup.ValidationError) {
          error.inner.forEach((error) => {
            console.log(error.path, error.message);
            errorMessages[error.path] = error.message;
          });
          formRef.current.setErrors(errorMessages);
        }
        detailMessages = errorMessages ? Object.keys(errorMessages).map(detail => `- ${errorMessages[detail]}`).join("\n") : "Nenhum detalhe adicional disponível.";
        messageError += `\n\nDados ausentes:\n${detailMessages}`;
        dispatch(
          setSnackbar({
            snackbarIsOpen: true,
            snackbarMessage: messageError,
            snackbarType: 'error',
          })
        );
      });
  };

  const handleEmployeeAllocationSubmit = (formData, { reset }) => {
    if (visualizeOnly) return;
    console.log("FORM DATA: ", formData);
    var withFixedValues = fixDate(fieldsMetaDataEdit, formData);
    withFixedValues = fixAutoComplete(fieldsMetaDataEdit, formData);

    if (action === "edit") {
      editAllocationValidation({ ...withFixedValues, changeOfJuridicalBondId: formData.changeOfJuridicalBond.id });
    }
  };

  const margin = history.location.pathname.includes("admin") ? "" : "4rem 7rem";

  const ellipsisText = {
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    overflow: "hidden",
    width: "100%",
  };

  const onRowClicked = (item, status) => {
    if (status === "ACTIVE") {
      getResourceMobilityInfoByEmployeeId(item.id);
      return setShowInfoModal(true);
    }
    onEmployeeClicked(item);
  };

  function formatDateToYYYYMMDD(date) {
    const validDate = date instanceof Date ? date : new Date(date);
    const year = validDate.getFullYear();
    const month = String(validDate.getMonth() + 1).padStart(2, "0");
    const day = String(validDate.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  }
  

  function isValidDate(dateString) {
    const date = new Date(dateString);
    return !isNaN(date.getTime()); 
  }

  let minYearConverted;
  const splited = currentEmployee?.startedOn?.split("-");
  minYearConverted = splited?.[2] + "-" + splited?.[1] + "-" + splited?.[0];

  console.log("Current employee: ", currentEmployee);
  console.log("Current action: ", action);

  useEffect(() => {
    const institutionOfOriginField = fieldsMetaDataEdit.find(
      (meta) => meta.field === "institutionOfOrigin.name"
    );

    if (institutionOfOriginField) {
      setInputOptions(institutionOfOriginField.options);
    }
  }, [fieldsMetaDataEdit]);

  return (
    <Box sx={{ heigh: "100vh", display: "flex" }}>
      <SideMenu />
      <Box
        sx={{ display: "flex", flexDirection: "column", padding: "25px 50px" }}
        style={{ margin }}
      >
        <AdminTitleHeader title={"Mudança de relação jurídica"} />
        <Box
          sx={{
            backgroundColor: "#fff",
            border: "1px solid #dfdfdf",
            borderBottom: "2px solid #dfdfdf",
            padding: "1rem 3rem",
            display: "flex",
          }}
        >
          <Box sx={{ width: "50%", display: "flex", gap: "2rem" }}>
            <Box
              sx={{
                width: "50%",
                display: "flex",
                flexDirection: "column",
                gap: "1rem",
                alignItems: "flex-end",
                color: theme.palette.primary.dark,
                fontWeight: "bold",
              }}
            >
              <span>Entidade</span>
              <span>Data de constituição</span>
            </Box>
            <Box
              sx={{
                width: "50%",
                display: "flex",
                flexDirection: "column",
                gap: "1rem",
                alignItems: "flex-start",
                color: "#3B4144",
              }}
            >
              <Tooltip title={unit?.institution?.name} placement="top">
                <span style={ellipsisText}>{unit?.institution?.name}</span>
              </Tooltip>
              <Tooltip
                title={unit?.institution?.constitutionDate}
                placement="top-start"
              >
                <span style={ellipsisText}>
                  {moment(unit?.institution?.constitutionDate).format(
                    "DD-MM-yyyy"
                  )}
                </span>
              </Tooltip>
            </Box>
          </Box>

          <Box sx={{ width: "50%", display: "flex", gap: "2rem" }}>
            <Box
              sx={{
                width: "50%",
                display: "flex",
                flexDirection: "column",
                gap: "1rem",
                alignItems: "flex-end",
                color: theme.palette.primary.dark,
                fontWeight: "bold",
              }}
            >
              <span>Orgão</span>
              <span>Pelouro</span>
            </Box>
            <Box
              sx={{
                width: "50%",
                display: "flex",
                flexDirection: "column",
                gap: "1rem",
                alignItems: "flex-start",
                color: "#3B4144",
              }}
            >
              <Tooltip title={unit?.name} placement="top">
                <span style={ellipsisText}>{unit?.name}</span>
              </Tooltip>
              <Tooltip title={unit?.expertiseName} placement="top">
                <span style={ellipsisText}>{unit?.expertiseName}</span>
              </Tooltip>
            </Box>
          </Box>
        </Box>

        <HorizontalMenu
          currentOption={horizontalMenuOption}
          options={horizontalButtons}
        />

        {/* PAGE CONTENT */}
        <Box
          sx={{
            backgroundColor: "#fff",
            padding: "2rem 1.5rem",
            display: "flex",
          }}
        >
          <Box
            sx={{
              flexBasis: `100%`,
            }}
          >
            <Form
              ref={formRef}
              onSubmit={handleEmployeeAllocationSubmit}
              initialData={currentEmployee}
              style={{ borderRadius: "unset", boxShadow: "none", padding: "0" }}
            >
              <Grid container spacing={1}>
                {fieldsMetaDataEdit.map((meta, i) => {
                  return meta?.title ? (
                    <Grid item xs={12}>
                      <Typography
                        sx={{
                          fontWeight: "bold",
                          fontSize: "42px"
                        }}
                        variant="h3"
                        color="primary"
                        mt={2}
                        gutterBottom
                      >
                        {!["Licença"].includes(meta.title) ? meta.title : null}
                      </Typography>
                      <Divider sx={{ bgcolor: "#f1f1f1", marginBottom: 2 }} />
                    </Grid>
                  ) : (
                    <>
                      {![
                        "select",
                        "checkbox",
                        "none",
                        "date",
                        "file",
                        "auto-complete",
                        "entity",
                      ].includes(meta.type) && (
                          <Grid item xs={3}>
                            <Input
                              key={i}
                              type={meta.type}
                              name={meta.field}
                              label={meta.label}
                              onChange={meta.onChange}
                              isMandatory={meta.isMandatory}
                              style={{ marginRight: 8, width: "85%" }}
                              disabled={true}
                            />
                          </Grid>
                        )}
                      <>
                        {meta.field === "startedOn" || meta.field === "endedOn"
                          ? meta.type == "date" && (
                            <Grid item xs={3}>
                              <DatePicker
                                key={i}
                                type={meta.type}
                                name={meta.field}
                                label={meta.label}
                                disabled={false}
                                withHours={meta.withHours}
                                dateFormat={meta.dateFormat}
                                placeholder={meta.placeholder}
                                isMandatory={meta.isMandatory}
                                notEndDate={
                                  meta.field == "endOfLeave" ? true : false
                                }
                                minYears={moment(
                                  unit?.institution?.constitutionDate
                                ).format("DD-MM-yyyy")}
                              /* style={{ marginRight: 8, width: "100%", border: "1px solid red" }} */
                              />
                            </Grid>
                          )
                          : null}

                        {meta.field === "position.id" ||
                          meta.field === "unit.id" ||
                          meta.field === "changeOfJuridicalBond.id" ||
                          meta.field === "juridicalBond.id"
                          ? meta.type == "select" && (
                            <Grid item xs={3}>
                              <Select
                                key={i}
                                name={meta.field}
                                label={meta.label}
                                options={meta.options}
                                onChange={meta.onChange}
                                isMandatory={meta.isMandatory}
                                disabled={i <= 10 ? true :
                                  meta.field === "institutionOfOrigin.id"
                                    ? true
                                    : false
                                }
                              />
                            </Grid>
                          )
                          : null}
                        {meta.field === "institutionOfOrigin.name"
                          ? meta.type == "entity" && (
                            <Grid item xs={3}>
                              <ReactSelect
                                key={i}
                                name={meta.field}
                                label={meta.label}
                                options={meta.options}
                                onChange={meta.onChange}
                                disabled={i <= 10 ? true :
                                  meta.field === "institutionOfOrigin.name"
                                    ? true
                                    : false
                                }
                                isMandatory={meta.isMandatory}
                              />
                            </Grid>
                          )
                          : null}
                      </>
                    </>
                  );
                })}
                <Grid item xs={3}>
                  <ReactSelect
                    name="institutionOfDestiny"
                    label={<InformationIcon label="Entidade de destino" />}
                    options={inputOptions}
                    disabled={false}
                    isMandatory={false}
                    onChange={(e) => setSelectedInputValue(e.target.value)}
                  />
                </Grid>
                <Grid item xs={3}>
                 <DatePicker
                  name="startedOnNew"
                  label={
                    <InformationIcon label={"Início de novas funções"} />
                  }
                  selected={startedOnNew ? new Date(startedOnNew) : null} 
                  onChange={(date) => {
                  if (date) {
                   const formattedDate = formatDateToYYYYMMDD(date);
                  setStartedOnNew(formattedDate); 
                  } else {
                   setStartedOnNew(null); 
                    }
                    }}
                   dateFormat="dd-MM-yyyy" 
                    isMandatory={true}
                  placeholderText="dd-mm-yyyy"
                  style={{ width: "100%" }}
                  showYearDropdown
                  scrollableYearDropdown
        
                  minDate={null}
                   maxDate={null}
                 onBlur={(e) => {
                const inputValue = e.target.value;
               if (inputValue && isValidDate(inputValue)) {
               const formattedDate = formatDateToYYYYMMDD(new Date(inputValue));
                setStartedOnNew(formattedDate);
                } else if (!inputValue) {
                  setStartedOnNew(null); 
                  }
                  }}
                  />
                 </Grid>
              </Grid>
              {!["ver"].includes(match.params.operation) && (
                <Box>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      width: "100%",
                      gap: "1rem",
                      marginTop: 9,
                    }}
                  >
                    <>
                      <Button
                        variant="outlined"
                        type="cancel"
                        onClick={(e) => {
                          e.preventDefault();
                          setAction(null);
                          history.goBack();
                        }}
                      >
                        Cancelar
                      </Button>
                      <Button type="submit" variant="contained">
                        Guardar
                      </Button>
                    </>
                  </Box>
                </Box>
              )}
            </Form>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default DestacamentoQuadros;
