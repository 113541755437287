import React, { useEffect, useState } from 'react';
import { Box, Avatar, useTheme } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { getEntityRequest } from '../../../../store/reducers/admin/actionCreators';
import RenderOnRole from '../../../../helpers/RenderOnRole';
import PersonalDataCard from './PersonalDataCard';
import InfoPessoaisMetaForm from '../../../../pages/quadros/account/MetaForm/InfoPessoaisMetaForm';
import GenericDataCard from './GenericDataCard';
import { format, parseISO } from 'date-fns';
import { ptBR } from 'date-fns/locale';
import banner from '../../../../assets/img/banner.jpg';
import {
  getEmployeeSection2Request,
  getEmployeeSection3Request,
  getEmployeeSection4Request,
  getEmployeeSection8Request,
  getEmployeeSectionRequest,
} from '../../../../store/reducers/users/actionCreators';
import ArrowCircleLeftRoundedIcon from '@mui/icons-material/ArrowCircleLeftRounded';
import CircleOutlined from '@mui/icons-material/CircleOutlined';
import CircleRounded from '@mui/icons-material/CircleRounded';

import { useHistory } from 'react-router-dom';
import styles from '../Quadro.module.css';
import { Styles } from './Styles';

const QuadroCV = ({ match }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const theme = useTheme();
  const employeeData = useSelector((state) => state.adminReducer.entityItem);
  const [literaryQualificationsObj, setLiteraryQualificationsObj] =
    useState(null);
  const [professionalExperiencesObj, setProfessionalExperiencesObj] =
    useState(null);
  const [idiomsObj, setIdiomsObj] = useState(null);
  const [trainingsObj, setTrainingsObj] = useState(null);
  const [administrationFramingObj, setAdministrationFramingObj] =
    useState(null);

  const literaryQualifications = useSelector(
    (state) => state.usersReducer.literaryQualifications
  );
  const professionalExperiences = useSelector(
    (state) => state.usersReducer.professionalExperiences
  );
  const trainings = useSelector((state) => state.usersReducer.trainings);
  const idioms = useSelector((state) => state.usersReducer.idiomas);
  const administrationFramings = useSelector(
    (state) => state.usersReducer.administrationFramings
  );

  useEffect(() => {
    if (match.params.employeeId) {
      dispatch(
        getEntityRequest({
          id: parseInt(match.params.employeeId),
          entity: 'employees',
        })
      );

      dispatch(
        getEmployeeSectionRequest({
          data: match.params.employeeId,
          sectionType: 'literary-qualifications',
          object: 'literaryQualifications',
          showNotification: false,
        })
      );

      dispatch(
        getEmployeeSection2Request({
          data: match.params.employeeId,
          sectionType: 'professional-experiences',
          object: 'professionalExperiences',
          showNotification: false,
        })
      );
      dispatch(
        getEmployeeSection3Request({
          data: match.params.employeeId,
          sectionType: 'idioms',
          object: 'idioms',
          showNotification: false,
        })
      );
      dispatch(
        getEmployeeSection4Request({
          data: match.params.employeeId,
          sectionType: 'trainings',
          object: 'trainings',
          showNotification: false,
        })
      );
      dispatch(
        getEmployeeSection8Request({
          data: match.params.employeeId,
          sectionType: 'administration-framing',
          object: 'administrationFramings',
          showNotification: false,
        })
      );
    }
    if (!match.params.employeeId) {
      return null;
    }
  }, [match?.params?.employeeId]);

  useEffect(() => {
    const getEmployeeCourse = (item) => {
      return item.course?.name
        ? item.course?.name
        : item.anotherCourse?.name
        ? item.anotherCourse?.name
        : '';
    };

    const getEducationalInstitution = (item) => {
      return item.educationalInstitution?.name
        ? item.educationalInstitution?.name
        : item.otherEducationalInstitution?.name
        ? item.otherEducationalInstitution?.name
        : '';
    };

    const generateAddress = (item) => {
      return `${item?.address || ''} ${
        item?.address && item.country?.name ? ', ' : ''
      } ${item.country?.name}`;
    };

    function getFormationNameByCod(cod) {
      const formationTypes = [
        { cod: 'POST_DOCTORAL', name: 'Pós Doutorado' },
        { cod: 'DOCTORAL', name: 'Doutorado' },
        { cod: 'POST_GRADUATION', name: 'Pós Graduação' },
        { cod: 'MASTER', name: 'Mestrado' },
        { cod: 'DEGREE', name: 'Licenciatura' },
        { cod: 'BACHELOR', name: 'Bacharelado' },
        { cod: 'SECONDARY_II', name: 'Ensino Médio - II Ciclo do Secundário' },
        { cod: 'SECONDARY_I', name: 'I Ciclo do Secundário' },
        { cod: 'ELEMENTARY', name: 'Ensino Primário' },
        { cod: 'ILLITERATE', name: 'Iletrado' },
      ];

      const formation = formationTypes.find((type) => type.cod === cod);

      return formation ? formation.name : '';
    }

    if (literaryQualifications?.length > 0) {
      setLiteraryQualificationsObj({
        title: 'Habilitações Literárias',
        items: literaryQualifications.map((literaryQualification) => {
          return {
            id: literaryQualification?.id,
            title: getFormationNameByCod(
              literaryQualification.levelOfEducation
            ),
            subTitle: getEmployeeCourse(literaryQualification),
            rows: [
              literaryQualification?.schoolingCycle?.name,
              `Média ${
                literaryQualification?.average
                  ? literaryQualification?.average
                  : 'N/A'
              } Val.`,
              `Concluído em ${
                literaryQualification?.completionYear
                  ? literaryQualification?.completionYear
                  : ''
              }`,
              literaryQualification?.course?.knowledgeArea?.name,
              getEducationalInstitution(literaryQualification),
              generateAddress(literaryQualification),
            ],
            list: literaryQualification?.mainCompetences?.map(
              (item) => item.name
            ),
          };
        }),
      });
    }
  }, [literaryQualifications]);

  useEffect(() => {
    const getProfession = (item) => {
      return item.profession?.name
        ? item.profession?.name
        : item.professionOther?.name
        ? item.professionOther?.name
        : '';
    };

    const getInstitution = (item) => {
      return item.institution?.name
        ? item.institution?.name
        : item.institutionOther?.name
        ? item.institutionOther?.name
        : '';
    };

    const generateDate = (date) => {
      if (!date) return 'n/d';
      const parsedDate = parseISO(date.split(' ')[0]); // Split to remove time, keep the date
      return format(parsedDate, 'dd MMM yyyy', { locale: ptBR });
    };

    if (professionalExperiences?.length > 0) {
      setProfessionalExperiencesObj({
        title: 'Experiência Profissional',
        items: professionalExperiences.map((professionalExperience) => {
          return {
            id: professionalExperience?.id,
            title: getProfession(professionalExperience),
            subTitle: getInstitution(professionalExperience),
            rows: [
              `${generateDate(
                professionalExperience.startDate
              )}. - ${generateDate(professionalExperience.endDate)}`,
              professionalExperience.functionDescription,
            ],
          };
        }),
      });
    }
  }, [professionalExperiences]);

  useEffect(() => {
    const generateDate = (date) => {
      if (!date) return 'n/d';
      const parsedDate = parseISO(date.split(' ')[0]); // Split to remove time, keep the date
      return format(parsedDate, 'dd MMM yyyy', { locale: ptBR });
    };

    if (administrationFramings?.length > 0) {
      setAdministrationFramingObj({
        title: 'Enquadramento',
        items: administrationFramings.map((administration) => {
          return {
            id: administration?.id,
            title: administration.positionName,
            subTitle: administration.unitInstitutionName,
            rows: [
              administration.categoryName,
              `Unidade: ${administration.unitName}`,
              `Entidade de origem: ${
                administration.institutionOfOriginName
                  ? administration.institutionOfOriginName
                  : 'Nenhuma'
              }`,
              `Número do agente: ${administration.agentNumber}`,
              `Carreira: ${administration.categoryCareerName}`,
              `${generateDate(administration.startedOn)}. - ${generateDate(
                administration.endedOn
              )}`,
            ],
          };
        }),
      });
    }
  }, [administrationFramings]);

  const getIdiomCircles = (text, item) => {
    switch (item) {
      case 'LOW':
        return (
          <Box sx={{ display: 'flex', gap: '1rem' }}>
            <span style={{ width: '20%' }}>{text}</span>
            <CircleRounded
              style={{
                color: `${theme.palette.primary.main}`,
                fontSize: '1.5rem',
              }}
            />
            <CircleOutlined
              style={{
                color: `${theme.palette.primary.main}`,
                fontSize: '1.5rem',
              }}
            />
            <CircleOutlined
              style={{
                color: `${theme.palette.primary.main}`,
                fontSize: '1.5rem',
              }}
            />
            <CircleOutlined
              style={{
                color: `${theme.palette.primary.main}`,
                fontSize: '1.5rem',
              }}
            />
          </Box>
        );
      case 'MEDIUM':
        return (
          <Box sx={{ display: 'flex', gap: '1rem' }}>
            <span style={{ width: '20%' }}>{text}</span>
            <CircleRounded
              style={{
                color: `${theme.palette.primary.main}`,
                fontSize: '1.5rem',
              }}
            />
            <CircleRounded
              style={{
                color: `${theme.palette.primary.main}`,
                fontSize: '1.5rem',
              }}
            />
            <CircleOutlined
              style={{
                color: `${theme.palette.primary.main}`,
                fontSize: '1.5rem',
              }}
            />
            <CircleOutlined
              style={{
                color: `${theme.palette.primary.main}`,
                fontSize: '1.5rem',
              }}
            />
          </Box>
        );
      case 'GOOD':
        return (
          <Box sx={{ display: 'flex', gap: '1rem' }}>
            <span style={{ width: '20%' }}>{text}</span>
            <CircleRounded
              style={{
                color: `${theme.palette.primary.main}`,
                fontSize: '1.5rem',
              }}
            />
            <CircleRounded
              style={{
                color: `${theme.palette.primary.main}`,
                fontSize: '1.5rem',
              }}
            />
            <CircleRounded
              style={{
                color: `${theme.palette.primary.main}`,
                fontSize: '1.5rem',
              }}
            />
            <CircleOutlined
              style={{
                color: `${theme.palette.primary.main}`,
                fontSize: '1.5rem',
              }}
            />
          </Box>
        );
      case 'VERY_GOOD':
        return (
          <Box sx={{ display: 'flex', gap: '1rem' }}>
            <span style={{ width: '20%' }}>{text}</span>
            <CircleRounded
              style={{
                color: `${theme.palette.primary.main}`,
                fontSize: '1.5rem',
              }}
            />
            <CircleRounded
              style={{
                color: `${theme.palette.primary.main}`,
                fontSize: '1.5rem',
              }}
            />
            <CircleRounded
              style={{
                color: `${theme.palette.primary.main}`,
                fontSize: '1.5rem',
              }}
            />
            <CircleRounded
              style={{
                color: `${theme.palette.primary.main}`,
                fontSize: '1.5rem',
              }}
            />
          </Box>
        );
      default:
        <Box sx={{ display: 'flex', gap: '1rem' }}>TESTE LOW</Box>;
    }
  };

  useEffect(() => {
    if (idioms?.length > 0) {
      setIdiomsObj({
        title: 'Idiomas',
        items: idioms.map((idiom) => {
          return {
            id: idiom?.id,
            title: idiom?.idiom?.name,
            rows: [
              getIdiomCircles('Leitura: ', idiom.readUnderstanding),
              getIdiomCircles('Oral: ', idiom.speechUnderstanding),
              getIdiomCircles('Escrita: ', idiom.writtenUnderstanding),
            ],
          };
        }),
      });
    }
  }, [idioms]);

  useEffect(() => {
    const getFormationType = (item) => {
      return item.formationType.name ? item.formationType.name : '';
    };

    const getEducationalInstitution = (item) => {
      return item.educationalInstitution?.name
        ? item.educationalInstitution?.name
        : item.otherEducationalInstitution?.name
        ? item.otherEducationalInstitution?.name
        : '';
    };

    const getWorkHours = (item) => {
      return item?.hourlyWorkload
        ? `${item?.hourlyWorkload} horas por dia`
        : '';
    };

    if (trainings?.length > 0) {
      setTrainingsObj({
        title: 'Formações e Capacitações',
        items: trainings.map((training) => {
          return {
            id: training?.id,
            title: getFormationType(training),
            subTitle: getEducationalInstitution(training),
            rows: [getWorkHours()],
          };
        }),
      });
    }
  }, [trainings]);

  const { personalDataCol1, personalDataCol2 } = InfoPessoaisMetaForm(
    employeeData?.typeEmployee,
    employeeData?.naturalness?.province?.id,
    null,
    [],
    employeeData?.foreignNationalityIdCard ? 'FOREIGN' : 'NATIONAL'
  );

  return (
    <RenderOnRole roles={['user-quadro', 'admin', 'user-entidade']}>
      <Box
        id='banner'
        sx={{
          display: 'flex',
          height: '245px',
          marginBottom: '20px',
          zIndex: 9,
        }}
      >
        <Avatar
          alt='banner'
          src={banner}
          variant='square'
          sx={{ width: '100%', height: '100%' }}
        />
        <Box
          onClick={() => history.goBack()}
          sx={{ marginLeft: '-95%', zIndex: '9', marginTop: '2rem' }}
        >
          <ArrowCircleLeftRoundedIcon className={styles.quadroCVBackButton} />
        </Box>
      </Box>
      <Styles
        style={{
          color: '#fff',
          boxShadow: '2px 2px 4px rgb(0 0 0 / 20%)',
          borderRadius: '4px',
        }}
      >
        {employeeData && (
          <Box
            id='personal-data'
            sx={{
              marginTop: '-9rem',
              zIndex: 99,
            }}
          >
            <PersonalDataCard
              entityId={employeeData.id}
              entity='employees'
              name={employeeData.name}
              column1={personalDataCol1}
              column2={personalDataCol2}
              data={employeeData}
              readOnly={true}
            >
              {literaryQualifications?.length > 0 && (
                <Box id='personal-data'>
                  <GenericDataCard genericData={literaryQualificationsObj} />
                </Box>
              )}
              {professionalExperiences && (
                <Box id='personal-data'>
                  <GenericDataCard genericData={professionalExperiencesObj} />
                </Box>
              )}
              {trainings?.length > 0 && (
                <Box id='personal-data'>
                  <GenericDataCard genericData={trainingsObj} />
                </Box>
              )}
              {administrationFramings?.length > 0 && (
                <Box id='personal-data'>
                  <GenericDataCard genericData={administrationFramingObj} />
                </Box>
              )}
              {idioms?.length > 0 && (
                <Box id='personal-data'>
                  <GenericDataCard genericData={idiomsObj} />
                </Box>
              )}
            </PersonalDataCard>
          </Box>
        )}
      </Styles>
      <Box></Box>
    </RenderOnRole>
  );
};

export default QuadroCV;
