import React from "react";
import Pagination from "@mui/material/Pagination";
import { useTheme } from "@mui/material";

const AppPagination = ({
  totalPages,
  totalElements,
  isSimple = false,
  onPageChange,
}) => {
  const theme = useTheme();

  return (
    <div>
      <div style={{ marginTop: "1rem", marginBottom: "1rem" }}>
        <div
          style={{
            display: "flex",
            justifyContent: "end",
            alignItems: "center",
            gap: "20px",
          }}
        >
          {/* {totalPages > 1 && !isSimple && ( */}
            <span
              style={{ textAlign: "end", color: "#A0A0A0", fontSize: "14px" }}
            >
              {" "}
              Total - <strong> {totalElements} </strong>{" "}
            </span>
          {/* )} */}

          <Pagination
            shape="rounded"
            style={{
              display: `${totalPages > 1 ? "flex" : "none"}`,
              justifyContent: "center",
            }}
            sx={{
              width: isSimple ? "100%" : "auto",
              // "& .MuiPagination-root": {
              //   display: "flex",
              //   justifyContent: "center !important",
              //   width: "100% !important",
              // },
              "& .MuiPaginationItem-root.Mui-selected": {
                backgroundColor: theme.palette.primary.main,
              },
              "& .MuiPaginationItem-root": {
                fontWeight: "600",
                fontSize: "16px",
                lineHeight: "24px",
                backgroundColor: "#f9f9f9",
              },
              "& .MuiPaginationItem-ellipsis": {
                display: "none",
              },
              "& .MuiPagination-ul": {
                border: isSimple ? "none" : "1px solid #E0E2E3 !important",
                boxSizing: isSimple ? "unset" : "border-box",
                borderRadius: isSimple ? "0" : "4px",
                display: "flex",
                gap: isSimple ? "2rem" : 0,
                width: isSimple ? "100%" : "auto",
              },
              "& li:first-child": {
                marginLeft: isSimple ? "auto" : "",
              },
              "& li:last-child": {
                marginRight: isSimple ? "auto" : "",
              },
              "& .MuiPaginationItem-page": {
                display: isSimple ? "none" : "inline-flex",
              },
            }}
            count={Math.round(totalPages)}
            color="primary"
            onChange={(_, page) => {
              window.scrollTo({ top: 0, behavior: "smooth" });
              return onPageChange(parseInt(page) - 1);
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default AppPagination;
