import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import CeaseMotives from '../../helpers/CeaseMotives';
import CeaseFunctionsSchema from '../../schemas/CeaseFunctionsSchema';
import KeycloakService from '../../services/KeycloakService';
import axios from '../../services/AxiosService';
import {
  getEntityRequest,
  getEntitiesRequest,
} from '../../store/reducers/admin/actionCreators';
import {
  getAllInstitutionsRequest,
  getAllInstitutionsPrivateRequest,
  getContractualRegimensTypesRequest,
  getJuridicalBondChangeRequest,
  getJuridicalBondsRequest,
  getPositionsRequest,
  getUnitEmployeesRequest,
  getUnitRequest,
  putCeaseFunctionsRequest,
  setIsLoading,
  setSnackbar,
} from '../../store/reducers/users/actionCreators';
import { useHistory } from 'react-router-dom';
import { SvgIcon, Box, Typography } from '@mui/material';
import { useTheBounce } from './useTheBounce';

export const InformationIcon = ({ label, isMandatory, ...props }) => (
  <Box display='flex' alignItems='center' justifyContent='center'>
    <Typography variant='body1' style={{ lineHeight: '30px' }}>
      {label}
    </Typography>
    {isMandatory ? <span style={{ color: 'red' }}>*</span> : null}
    <SvgIcon {...props} style={{ fontSize: 30, marginLeft: 8 }}>
      <path
        d='M8.9872 0.999854C10.0575 0.998226 11.1173 1.21138 12.1038 1.62668C13.0902 2.04199 13.9833 2.65101 14.7302 3.41769C15.4634 4.17027 16.0411 5.05989 16.4304 6.03575C16.8198 7.01161 17.0131 8.05461 16.9993 9.10518C16.9856 10.1557 16.765 11.1933 16.3503 12.1587C15.9355 13.124 15.3347 13.9982 14.5821 14.7313C13.3007 15.9765 11.6394 16.7569 9.86289 16.9482C8.0864 17.1394 6.29701 16.7305 4.77994 15.7866L4.85556 15.86L1.71635 16.9849L2.92643 14.2063C1.60294 12.6624 0.916992 10.6725 1.00803 8.64099C1.09906 6.60951 1.96025 4.68891 3.41653 3.2696C4.90747 1.8172 6.90577 1.00301 8.9872 0.999854Z'
        fill='#81D8F8'
        fillOpacity='0.3'
        stroke='#81D8F8'
        strokeOpacity='0.3'
        strokeWidth='1.032'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M9.9491 12.1795C9.45666 12.7678 9.13658 13.0134 8.91723 13.0134C8.76463 13.0134 8.71271 12.8614 8.74672 12.5187C8.84925 11.6279 9.43997 8.76337 9.60803 7.74008C9.77673 6.86455 9.72666 6.41016 9.50672 6.41016C9.08593 6.41016 8.05414 7.18787 7.37816 7.96487C7.34356 8.02455 7.27685 8.34574 7.31141 8.45883C7.31141 8.49768 7.36148 8.51708 7.36148 8.51708C7.76744 8.15633 8.08874 7.9468 8.27531 7.9468C8.35871 7.9468 8.39149 8.09803 8.34206 8.36443C8.13939 9.50219 7.80141 11.2684 7.54809 12.7281C7.31145 14.0011 7.4622 14.5124 7.81746 14.5124C8.17273 14.5124 9.15272 13.9804 9.94914 12.785C9.98125 12.692 10.014 12.3327 9.99857 12.2363C9.99853 12.1996 9.9491 12.1795 9.9491 12.1795Z'
        fill='#2B404E'
        stroke='#2B404E'
        strokeWidth='0.3'
      />
      <path
        d='M9.66109 4.00195C9.46318 4.00195 9.22036 4.0995 9.10466 4.22204C9.05888 4.28321 9.01229 4.46601 9 4.56332C9.01229 4.78364 9.06992 4.90572 9.18603 4.97938C9.2432 5.02738 9.62591 4.99121 9.70728 4.94189C9.84798 4.84481 9.97383 4.64904 9.99756 4.41689C10.0077 4.30775 9.98568 4.16021 9.93947 4.08744C9.91622 4.05107 9.82298 4.00195 9.66109 4.00195Z'
        fill='#2B404E'
      />
    </SvgIcon>
  </Box>
);

const useAlocacaoQuadros = (institutionId, unitId, employeeId, operation) => {

  const dispatch = useDispatch();
  const history = useHistory();

  const positions = useSelector((state) => state.usersReducer.positions);
  const contractualRegimens = useSelector(
    (state) => state.usersReducer.contractualRegimens
  );
  const juridicalBonds = useSelector(
    (state) => state.usersReducer.juridicalBonds
  );
  const juridicalBondChange = useSelector(
    (state) => state.usersReducer.juridicalBondChange
  );
  const institution = useSelector((state) => state.adminReducer.entityItem);

  const institutions = useSelector((state) => state.usersReducer.institutions);

  const unit = useSelector((state) => state.usersReducer.unit);
  const unitEmployees = useSelector(
    (state) => state.usersReducer.unitEmployees
  );
  const unitEmployeesTotalPages = useSelector(
    (state) => state.usersReducer.unitEmployeesTotalPages
  );
  const unitEmployeesTotalElements = useSelector(
    (state) => state.usersReducer.unitEmployeesTotalElements
  );
  const employeesToAssign = useSelector(
    (state) => state.usersReducer.employeesToAssign
  );
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const { theBounce } = useTheBounce()

  const [institutionEmployees, setInstitutionEmployees] = useState([]);
  const [inactiveEmployees, setInactiveEmployees] = useState([]);
  const [institutionEmployeesTotalPages, setInstitutionEmployeesTotalPages] =
    useState([]);
  const [totalPageInactive, setTotalPageInactive] =
    useState([]);
  const [currentEmployee, setCurrentEmployee] = useState(null);
  const [clickedEmployee, setClickedEmployee] = useState(null);
  const [id, setId] = useState(null);
  const [isInstitutionDisabled, setIsInstitutionDisabled] = useState(true);
  const [pageIndex, setPageIndex] = useState(0);
  const [inactivePageIndex, setInactivePageIndex] = useState(0);
  const [sideTablePageIndex, setSideTablePageIndex] = useState(0);
  const [query, setQuery] = useState('');
  const [inactiveQuery, setInactiveQuery] = useState("");
  const [dataResource, setDataResource] = useState([]);
  const [userInfo, setUserInfo] = useState();
  const getUserInfo = async () => {
    const response = await KeycloakService.getUserInfo();
    const { preferred_username } = response;
    setUserInfo(preferred_username);
  };

  const handleDateChange = (date, type) => {
    if (type === 'start') {
      setStartDate(date);
    } else if (type === 'end') {
      setEndDate(date);
    }
  };
  const emptyEmployee = {
    id: null,
    bi: null,
    name: null,
    career: null,
    subCareer: null,
    category: null,
    position: null,
    department: null,
    isConsultant: null,
    hiringRegime: null,
    bond: null,
    agentNumber: null,
    employeeCode: null,
    legalRelationshipChange: null,
    originInstitution: null,
    startDate: null,
    endDate: null,
    responsavel: userInfo,

    historicTypeId: 6,
  };
  // Função para enviar os dados atualizados para o backend
  const saveCeaseFunctions = () => {
    const formattedStartDate = startDate
      ? moment(startDate).format('YYYY-MM-DD')
      : null;
    const formattedEndDate = endDate
      ? moment(endDate).format('YYYY-MM-DD')
      : null;

    const dataToSave = {

      startedOn: formattedStartDate,
      endedOn: formattedEndDate,

      // Outros campos necessários
    };

    dispatch(putCeaseFunctionsRequest(dataToSave))
      .then(() => {
        dispatch(setSnackbar('success', 'Funções atualizadas com sucesso.'));
        // Lógica adicional após o salvamento
      })
      .catch((error) => {
        dispatch(setSnackbar('error', 'Erro ao salvar funções.'));
        console.error('Erro ao salvar funções:', error);
      });
  };

  const getResourceMobilityByEmployeeId = (id) => {
    axios
      .get(`/resource-mobility?employeeId=${id}`, {
        headers: {
          Authorization: `Bearer ${KeycloakService.getToken()}`,
        },
      })
      .then((response) => {
        if (response.data?.content.length > 0) {
          return setCurrentEmployee(response.data.content[0]);
        }
      });
  };

  const getResourceMobilityInfoByEmployeeId = (id) => {
    axios
      .get(`/resource-mobility?employeeId=${id}`, {
        headers: {
          Authorization: `Bearer ${KeycloakService.getToken()}`,
        },
      })
      .then((response) => {
        if (response.data?.content.length > 0) {
          return setClickedEmployee(response.data.content[0]);
        }
      });
  };

  function transformData(originalData) {
    const transformedArray = [];
    for (const key in originalData) {
      if (originalData.hasOwnProperty(key)) {
        transformedArray.push({ cod: key, name: originalData[key] });
      }
    }
    return transformedArray;
  }

  const getAllResourceMobility = () => {
    axios
      .get(`/resource-mobility/leave-types`, {
        headers: {
          Authorization: `Bearer ${KeycloakService.getToken()}`,
        },
      })
      .then((response) => {
        if (response) {
          setDataResource(transformData(response.data));
        }
      });
  };

  useEffect(() => {

    if (!userInfo) {
      getUserInfo();
    }
  }, [userInfo]);


  useEffect(async () => {
    getAllResourceMobility();
    dispatch(
      getPositionsRequest({
        queryDslFilters: [{ label: 'size', value: 1000 }],
      })
    );
    dispatch(getContractualRegimensTypesRequest());
    dispatch(getJuridicalBondsRequest());
    dispatch(getJuridicalBondChangeRequest());
    dispatch(getAllInstitutionsRequest());

    if (unitId) {
      dispatch(getUnitRequest({ institutionId, unitId }));
    }
    if (
      [
        'ver',
        'editar',
        'mobilidade',
        'exonom',
        'progressão na carreira',
      ].includes(operation) &&
      employeeId
    ) {
      getResourceMobilityByEmployeeId(employeeId);
    }
  }, []);

  useEffect(() => {
    if (employeesToAssign?.length > 0) {
      setInstitutionEmployees(employeesToAssign);
    }
  }, [employeesToAssign]);

  useEffect(() => {
    if (!unitId) return;
    const indexBy = 'default';
    switch (indexBy) {
      case 'default':
        dispatch(
          getUnitEmployeesRequest({
            queryDslFilters: [
              { label: 'unitId', value: unitId },
              {
                label: 'size',
                value: process.env.REACT_APP_ALLOCATION_TABLE_SIZE,
              },
              { label: 'page', value: pageIndex },
            ],
          })
        );
        break;

      default:
        dispatch(
          getUnitEmployeesRequest({
            queryDslFilters: [
              { label: 'unitId', value: unitId },
              {
                label: 'size',
                value: process.env.REACT_APP_ALLOCATION_TABLE_SIZE,
              },
              { label: 'page', value: pageIndex },
            ],
          })
        );
        break;
    }
  }, [pageIndex]);

  useEffect(() => {

    if (!userInfo) {
      getUserInfo();
    }
  }, [userInfo]);


  useEffect(() => {
    if (operation === "alocar") {
      theBounce(() => {
        axios
          .get(
            `/resource-mobility/to-assign/${institutionId}?nameOrBi=${query}&size=${process.env.REACT_APP_ALLOCATION_TABLE_SIZE}&page=${sideTablePageIndex}`,
            {
              headers: {
                Authorization: `Bearer ${KeycloakService.getToken()}`,
              },
            }
          )
          .then((response) => {
            if (!response.data) return;
            setInstitutionEmployees(response.data.content);
            setInstitutionEmployeesTotalPages(response.data.totalPages);
            setPageIndex(response.data.totalElements);
          })
          .catch((error) => {
            console.error(error);
            dispatch(setIsLoading(false));
          });
      })
    }
  }, [query, operation, sideTablePageIndex]);

  useEffect(() => {
    const regex = /^(\d{9})([a-zA-Z]{2})(\d{3})$/;
    if (regex.test(inactiveQuery)) {
      theBounce(() => {
        axios
          .get(`/employees/inative?bi=${inactiveQuery}`, {
            headers: {
              Authorization: `Bearer ${KeycloakService.getToken()}`,
            },
          })
          .then((response) => {
            if (!response.data) return;
            setInactiveEmployees(response.data.content);
            setTotalPageInactive(response.data.totalPages);
          })
          .catch((error) => {
            console.log(error);
            dispatch(setIsLoading(false));
          });
      });
    }
    else {
      setInactiveEmployees([]);
      setTotalPageInactive(0);
    }
  }, [inactiveQuery, operation, inactivePageIndex]);

  const fieldsMetaData = [
    {
      title: 'Dados do Quadro',
    },
    {
      field: 'employee.name',
      label: 'Nome do Quadro',
      type: 'text',
      disabled: true,
      size: { width: { xs: '100%', sm: '50%', md: '46%' } },
    },
    {
      field: 'employee.bi',
      label: 'BI',
      type: 'text',
      disabled: true,
      size: { width: { xs: '100%', sm: '50%', md: '46%' } },
      isMandatory: true,
    },
    {
      field: 'agentNumber',
      label: 'Número de Agente',
      type: 'text',
      disabled:
        operation === 'ver' ||
        operation === 'mobilidade' ||
        operation === 'exonom' ||
        operation === 'progressão na carreira',
      size: { width: { xs: '100%', sm: '50%', md: '46%' } },
    },
    {
      field: 'ssNumber',
      label: 'Número de Seg. Social',
      type: 'text',
      disabled:
        operation === 'ver' ||
        operation === 'mobilidade' ||
        operation === 'exonom' ||
        operation === 'progressão na carreira',
      size: { width: { xs: '100%', sm: '50%', md: '46%' } },
    },
    {
      field: 'cifNumber',
      label: 'Código Identificação Funcionário',
      type: 'text',
      disabled:
        operation === 'ver' ||
        operation === 'mobilidade' ||
        operation === 'progressão na carreira',
      disabled: operation === 'ver',
      size: { width: { xs: '100%', sm: '50%', md: '46%' }, paddingBottom: 5 },
    },
    { title: 'Enquadramento' },
    {
      field: 'contractualRegimen.id',
      label: (
        <InformationIcon label={'Regime de Contrato'} isMandatory={true} />
      ),
      type: 'select',
      options: contractualRegimens,
      fieldId: 'contractualRegimen.id',
      disabled:
        operation === 'ver' ||
        operation === 'mobilidade' ||
        operation === 'progressão na carreira',
      disabled: operation === 'ver',
      size: { width: { xs: '100%', sm: '50%', md: '46%' } },
    },
    {
      field: 'juridicalBond.id',
      label: (
        <InformationIcon
          label={'Vínculo / Relação Jurídica'}
          isMandatory={true}
        />
      ),
      type: 'select',
      options: juridicalBonds,
      fieldId: 'juridicalBond.id',
      disabled:
        operation === 'ver' ||
        operation === 'mobilidade' ||
        operation === 'exonom' ||
        operation === 'progressão na carreira',
      size: { width: { xs: '100%', sm: '50%', md: '46%' } },
    },
    {
      field: 'startedOn',
      label: <InformationIcon label={'Início Funções'} isMandatory={true} />,
      type: 'date',
      placeholder: 'dd-MM-yyyy',
      dateFormat: 'dd-MM-yyyy',
      disabled: operation === 'ver',
      size: { width: { xs: '100%', sm: '50%', md: '46%' } },
    },

    {
      field: 'changeOfJuridicalBond.id',
      label: (
        <InformationIcon
          label={'Mudança de Relação Jurídica'}
          isMandatory={true}
        />
      ),
      type: 'select',
      options: juridicalBondChange,
      isInput: false,
      fieldId: 'changeOfJuridicalBond.id',
      onChange: (e) => {
        const juridicalBondChangeObj = juridicalBondChange.find(
          (item) => item.name === 'Não Existe Alteração'
        );
        return e.target.value == juridicalBondChangeObj?.id
          ? setIsInstitutionDisabled(true)
          : setIsInstitutionDisabled(false);
      },
      disabled: operation === 'ver',
      size: { width: { xs: '100%', sm: '50%', md: '46%' } },
    },
    {
      fieldId: 'category.id',
      field: 'category',
      label: <InformationIcon label={'Categoria Actual'} isMandatory={true} />,
      type: 'auto-complete',
      split: true,
      isAtual: true,
      splitOptionsCodes: ['/careers', '/categories/career'],
      alloptionscode: '/categories',
      optionscode: '/categories/suggestion',
      disabled: true,
      placeholder: 'Pesquisar categorias existentes',
      size: { width: { xs: '100%', sm: '50%', md: '46%' } },
    },
    {
      field: 'endedOn',
      label: <InformationIcon label={'Fim Funções'} />,
      type: 'date',
      placeholder: 'dd-MM-yyyy',
      dateFormat: 'dd-MM-yyyy',
      disabled: operation === 'ver',
      size: { width: { xs: '100%', sm: '50%', md: '46%' } },
    },
    {
      field: 'institutionOfOrigin.name',
      label: <InformationIcon label={'Entidade de Origem'} />,
      type: 'entity',
      options: institutions,
      fieldId: 'institutionOfOrigin.name',
      disabled: isInstitutionDisabled,
      disabled:
        operation === 'ver' ||
        operation === 'mobilidade' ||
        operation === 'progressão na carreira' ||
        operation === 'exonom',
      size: { width: { xs: '100%', sm: '50%', md: '46%' } },
    },

    {
      field: 'position.id',
      label: <InformationIcon label={'Cargo'} />,
      type: 'select',
      options: positions,
      fieldId: 'position.id',
      disabled: operation === 'ver',
      size: { width: { xs: '100%', sm: '50%', md: '46%' } },
    },
    {
      fieldId: 'category.id',
      field: 'category',
      label: <InformationIcon label={'Categoria'} isMandatory={true} />,
      type: 'auto-complete',
      split: true,
      splitOptionsCodes: ['/careers', '/categories/career'],
      alloptionscode: '/categories',
      optionscode: '/categories/suggestion',
      disabled: operation === 'ver',
      placeholder: 'Pesquisar categorias existentes',
      size: { width: { xs: '100%', sm: '50%', md: '46%' } },
    },
    {
      field: 'document',
      label: 'Anexo',
      type: 'file',
      disabled: operation === 'ver',
      size: {
        width: { xs: '100%', sm: '50%', md: '46%' },
        marginTop: 2.7,
      },
    },
    {
      field: 'consultant',
      label: <InformationIcon label={'Consultor'} />,
      type: 'checkbox',
      disabled:
        operation === 'ver' ||
        operation === 'mobilidade' ||
        operation === 'progressão na carreira' ||
        operation === 'exonom',
      size: {
        width: '100%',
        paddingBottom: 5,
      },
    },
    { title: 'Licença' },
    {
      field: 'allocationLeaveTypeRequest',
      label: 'Tipo de Licença',
      fieldId: 'allocationLeaveTypeRequest.cod',
      type: 'select',
      options: dataResource,
      disabled:
        operation === 'ver' ||
        operation === 'mobilidade' ||
        operation === 'progressão na carreira' ||
        operation === 'exonom',
      size: { width: { xs: '100%', sm: '50%', md: '46%' } },
    },
    {
      field: 'startOfLeave',
      label: 'Início Licença',
      type: 'date',
      placeholder: 'dd-MM-yyyy',
      dateFormat: 'dd-MM-yyyy',
      disabled:
        operation === 'ver' ||
        operation === 'mobilidade' ||
        operation === 'progressão na carreira' ||
        operation === 'exonom',
      size: { width: { xs: '100%', sm: '50%', md: '46%' } },
    },
    {
      field: 'endOfLeave',
      label: 'Fim Licença',
      type: 'date',
      placeholder: 'dd-MM-yyyy',
      dateFormat: 'dd-MM-yyyy',
      disabled:
        operation === 'ver' ||
        operation === 'mobilidade' ||
        operation === 'progressão na carreira' ||
        operation === 'exonom',
      size: { width: { xs: '100%', sm: '50%', md: '46%' } },
    },
  ];

  const fieldsMetaDataEdit = [
    {
      title: 'Dados do Quadro',
    },
    {
      field: 'employee.name',
      label: 'Nome do Quadro',
      type: 'text',
      disabled: true,
      size: { width: { xs: '100%', sm: '50%', md: '46%' } },
    },
    {
      field: 'employee.bi',
      label: 'BI',
      type: 'text',
      disabled: true,
      size: { width: { xs: '100%', sm: '50%', md: '46%' } },
      isMandatory: true,
    },
    {
      field: 'agentNumber',
      label: 'Número de Agente',
      type: 'text',
      disabled:
        operation === 'ver' ||
        operation === 'mobilidade' ||
        operation === 'exonom' ||
        operation === 'progressão na carreira',
      size: { width: { xs: '100%', sm: '50%', md: '46%' } },
    },
    {
      field: 'ssNumber',
      label: 'Número de Seg. Social',
      type: 'text',
      disabled:
        operation === 'ver' ||
        operation === 'mobilidade' ||
        operation === 'exonom' ||
        operation === 'progressão na carreira',
      size: { width: { xs: '100%', sm: '50%', md: '46%' } },
    },
    {
      field: 'cifNumber',
      label: 'Código Identificação Funcionário',
      type: 'text',
      disabled:
        operation === 'ver' ||
        operation === 'mobilidade' ||
        operation === 'progressão na carreira',
      disabled: operation === 'ver',
      size: { width: { xs: '100%', sm: '50%', md: '46%' }, paddingBottom: 5 },
    },
    { title: 'Enquadramento Actual' },
    {
      field: 'startedOn',
      label: <InformationIcon label={'Início Funções'} isMandatory={true} />,
      type: 'date',
      placeholder: 'dd-MM-yyyy',
      dateFormat: 'dd-MM-yyyy',
      disabled: operation === 'ver',
      size: { width: { xs: '100%', sm: '50%', md: '46%' } },
    },
    {
      field: "changeOfJuridicalBond.id",
      label: (
        <InformationIcon
          label={"Mudança de Relação Jurídica"}
          isMandatory={true}
        />
      ),
      type: "select",
      options: juridicalBondChange,
      isInput: false,
      fieldId: "changeOfJuridicalBond.id",
      onChange: (e) => {
        const juridicalBondChangeObj = juridicalBondChange.find(
          (item) => item.name === "Não Existe Alteração"
        );
        return e.target.value == juridicalBondChangeObj?.id
          ? setIsInstitutionDisabled(true)
          : setIsInstitutionDisabled(false);
      },
      disabled: operation === "ver",
      size: { width: { xs: "100%", sm: "50%", md: "46%" } },
    },
    {
      field: 'institutionOfOrigin.name',
      label: <InformationIcon label={'Entidade actual'} />,
      type: 'entity',
      options: institutions,
      fieldId: 'institutionOfOrigin.name',
      disabled: isInstitutionDisabled,
      disabled:
        operation === 'ver' ||
        operation === 'mobilidade' ||
        operation === 'progressão na carreira' ||
        operation === 'destacamento' ||
        operation === 'exonom',
      size: { width: { xs: '100%', sm: '50%', md: '46%' } },
    },
    {
      field: 'endedOn',
      label: <InformationIcon label={'Fim de Funções'} />,
      type: 'date',
      placeholder: 'dd-MM-yyyy',
      dateFormat: 'dd-MM-yyyy',
      disabled: operation === 'ver',
      size: { width: { xs: '100%', sm: '50%', md: '46%' } },
    },
    { title: "Novo enquadramento" },
    /* {
      field: 'startedOn',
      label: <InformationIcon label={'Início de novas Funções'} isMandatory={true} />,
      type: 'date',
      placeholder: 'dd-MM-yyyy',
      dateFormat: 'dd-MM-yyyy',
      disabled: operation === 'ver',
      size: { width: { xs: '100%', sm: '50%', md: '46%' } },
    }, */
    {
      field: "changeOfJuridicalBond.id",
      label: (
        <InformationIcon
          label={"Mudança de Relação Jurídica"}
          isMandatory={true}
        />
      ),
      type: "select",
      options: juridicalBondChange,
      isInput: false,
      fieldId: "changeOfJuridicalBond.id",
      onChange: (e) => {
        const juridicalBondChangeObj = juridicalBondChange.find(
          (item) => item.name === "Não Existe Alteração"
        );
        return e.target.value == juridicalBondChangeObj?.id
          ? setIsInstitutionDisabled(true)
          : setIsInstitutionDisabled(false);
      },
      disabled: operation === "ver",
      size: { width: { xs: "100%", sm: "50%", md: "46%" } },
    },

    {
      field: 'institution.name',
      label: 'Entidade de Destino',
      type: 'select',
      options: institutions,
      fieldId: 'institution.id',
    },
  ];

  const fixAutoComplete = (formMeta, dataToBeReturned) => {
    formMeta.forEach((meta) => {
      if (meta.type == 'auto-complete') {
        const parentKey = meta.fieldId.split('.')[0];
        if (!dataToBeReturned[parentKey]) return dataToBeReturned;
        let idFromAutoComplete = 'null';
        if (currentEmployee) {
          idFromAutoComplete = currentEmployee[parentKey]?.id;
        }
        const nameFromAutoComplete = eval('dataToBeReturned.' + meta.field);
        if (idFromAutoComplete !== 'null' && idFromAutoComplete !== undefined) {
          if (
            dataToBeReturned[parentKey].name === currentEmployee[parentKey].name
          ) {
            dataToBeReturned[parentKey] = { id: idFromAutoComplete };
            return dataToBeReturned;
          }
        }

        if (nameFromAutoComplete !== 'null') {
          if (!isNaN(nameFromAutoComplete)) {
            dataToBeReturned[parentKey] = { id: nameFromAutoComplete };
          }
          try {
            const obj = JSON.parse(nameFromAutoComplete);
            return (dataToBeReturned[parentKey] = obj);
          } catch (e) {
            return dataToBeReturned;
          }
        }

        if (idFromAutoComplete === 'null' && nameFromAutoComplete === 'null') {
          return dataToBeReturned;
        }
      }
    });
    return dataToBeReturned;
  };

  const fixSelect = (formMeta, dataToBeReturned) => {
    formMeta.forEach((meta) => {
      if (meta.type == 'select' && meta.options) {
        const parentKey = meta.fieldId.split('.')[0];

        if (!dataToBeReturned[parentKey]) return dataToBeReturned;

        const idFromSelect = eval('dataToBeReturned.' + meta.fieldId);

        if (idFromSelect === 'null') {
          dataToBeReturned[parentKey] = '';
          return dataToBeReturned;
        }

        meta.options.forEach((option) => {
          if (option.id && option.id == idFromSelect)
            dataToBeReturned[parentKey] = option;
          else if (option.cod && option.cod == idFromSelect)
            dataToBeReturned[parentKey] = option.cod;
        });
      }
    });
    return dataToBeReturned;
  };

  const fixDate = (formMeta, dataToBeReturned) => {
    formMeta.forEach((meta) => {
      if (meta.type === 'date' && dataToBeReturned.hasOwnProperty(meta.field)) {
        const parentKey = meta.field;
        const dateValue = dataToBeReturned[parentKey];

        if (dateValue) {
          const formattedDate = moment(dateValue, 'DD-MM-YYYY').format(
            'YYYY-MM-DD'
          );
          dataToBeReturned[parentKey] = formattedDate;
        }
      }
    });
    return dataToBeReturned;
  };
  const dataActivity = JSON.parse(localStorage.getItem('userData'));

  const onEmployeeClicked = (employee) => {
    setCurrentEmployee({ employee });
    setId(employee.id);
  };

  const onDataSubmitFile = async (id, file) => {
    let formdata = new FormData();
    formdata.append('file', file);
    try {
      const data = await axios.post(
        `documents/prof-allocation/${id}`,
        formdata,
        {
          headers: {
            Authorization: `Bearer ${KeycloakService.getToken()}`,
          },
        }
      );

      if (data.status == 200) {
        dispatch(
          setSnackbar({
            snackbarIsOpen: true,
            snackbarMessage: 'Arquivo Carregado com sucesso',
            snackbarType: 'success',
          })
        );
        history.goBack();
      } else {
        dispatch(
          setSnackbar({
            snackbarIsOpen: true,
            snackbarMessage: data.data?.message,
            snackbarType: 'error',
          })
        );
      }
    } catch (error) {
      console.error(error);
    }
  };

  const onAllocationLeaveTypeRequest = async (
    id,
    allocationLeaveTypeRequest,
    startOfLeave,
    endOfLeave
  ) => {
    let contents = {
      allocationLeaveTypeRequest,
      startOfLeave,
      endOfLeave,
    };
    try {
      const data = await axios.put(
        `/resource-mobility/${id}/leave-type`,
        {
          ...contents,
        },
        {
          headers: {
            Authorization: `Bearer ${KeycloakService.getToken()}`,
          },
        }
      );

      if (data.status == 200) {
        dispatch(
          setSnackbar({
            snackbarIsOpen: true,
            snackbarMessage: 'Dados Carregados com sucesso',
            snackbarType: 'success',
          })
        );
        history.goBack();
      } else {
        dispatch(
          setSnackbar({
            snackbarIsOpen: true,
            snackbarMessage: data.data?.message,
            snackbarType: 'error',
          })
        );
      }
    } catch (error) {
      console.error(error);
    }
  };

  const postAssignEmployee = (data) => {
    dispatch(setIsLoading(true));
    axios
      .post(
        `/resource-mobility/assign-employee`,
        {
          ...data,
        },
        {
          headers: {
            Authorization: `Bearer ${KeycloakService.getToken()}`,
          },
        }
      )
      .then((response) => {
        data.document.file &&
          onDataSubmitFile(response?.data?.id, data.document.file);
        data.allocationLeaveTypeRequest &&
          onAllocationLeaveTypeRequest(
            response?.data?.id,
            data.allocationLeaveTypeRequest,
            data.startOfLeave,
            data.endOfLeave
          );
        if (!response.data) return;
        dispatch(setIsLoading(false));
        dispatch(
          setSnackbar({
            snackbarIsOpen: true,
            snackbarMessage: `${data.onSuccessMessage}`,
            snackbarType: 'success',
          })
        );
        history.goBack();
      })
      .catch((error) => {
        console.error(error);
        dispatch(setIsLoading(false));
      });
  };

  const updateAssignedEmployee = (data) => {
    dispatch(setIsLoading(true));
    axios
      .put(
        `/resource-mobility/update`,
        {
          ...data,
        },
        {
          headers: {
            Authorization: `Bearer ${KeycloakService.getToken()}`,
          },
        }
      )
      .then((response) => {
        if (!response.data) return;
        dispatch(setIsLoading(false));
        dispatch(
          setSnackbar({
            snackbarIsOpen: true,
            snackbarMessage: `Quadro editado com sucesso`,
            snackbarType: 'success',
          })
        );
        history.goBack();
      })
      .catch((error) => {
        console.error(error);
        dispatch(setIsLoading(false));
      });
  };

  const onAddValidationComplete = (formData) => {
    postAssignEmployee({
      ...formData,
    });
  };

  const onEditValidationComplete = (formData) => {
    updateAssignedEmployee(formData);
  };

  const ceaseFunctionsFieldsMetaData = (employeeName) => {
    return {
      title: `EXTINÇÃO DA RELAÇÃO JURÍDICA de ${employeeName}`,
      formMeta: [
        {
          field: 'date',
          label: 'Data de cessão',
          type: 'date',
          placeholder: 'dd-MM-yyyy',
          dateFormat: 'dd-MM-yyyy',
        },
        {
          field: 'institution.name',
          label: 'Entidade de Destino',
          type: 'select',
          options: institutions,
          fieldId: 'institution.id',
        },
        {
          field: 'obs',
          label: 'Observação',
          type: 'textarea',
        },
      ],
    };
  };



  const ceaseFunctionsDialogSchemaValidation = CeaseFunctionsSchema;

  const handleCeaseFunctions = (obj, withCurrentEmployee) => {
    if (!currentEmployee?.employee.id) {
      return dispatch(
        setSnackbar({
          snackbarIsOpen: true,
          snackbarMessage: `Nenhuma alteração foi feita porque as funções já foram cessadas anteriormente.`,
          snackbarType: 'error',
        })
      );
    }

    dispatch(
      putCeaseFunctionsRequest({
        allocationId: currentEmployee?.id,
        employeeId: currentEmployee?.employee?.id,
        unitId: !withCurrentEmployee ? unitId : currentEmployee?.unit?.id,
        date: obj.date,
        motive: "UNIT_EXTINCTION",
        obs: obj.obs,
        showNotification: true,
        responsavel: userInfo,
        institutionId: obj.institution.id,
        onSuccessMessage: `Funções de ${currentEmployee?.employee?.name} cessadas com sucesso`,
      })
    );
  };

  return {
    institution,
    unit,
    institutionEmployees,
    unitEmployees,
    currentEmployee,
    setCurrentEmployee,
    fieldsMetaData,
    fieldsMetaDataEdit,
    fixSelect,
    fixDate,
    fixAutoComplete,
    onEmployeeClicked,
    emptyEmployee,
    sideTablePageIndex,
    inactivePageIndex,
    inactiveEmployees,
    inactiveQuery,
    onAddValidationComplete,
    onEditValidationComplete,
    id,
    setId,
    ceaseFunctionsFieldsMetaData,
    ceaseFunctionsDialogSchemaValidation,
    handleCeaseFunctions,
    getResourceMobilityByEmployeeId,
    unitEmployeesTotalPages,
    pageIndex,
    setPageIndex,
    setSideTablePageIndex,
    setInactivePageIndex,
    institutionEmployeesTotalPages,
    query,
    setQuery,
    getResourceMobilityInfoByEmployeeId,
    unitEmployeesTotalElements,
    clickedEmployee,
    setInactiveQuery,
    totalPageInactive
  };
};

export default useAlocacaoQuadros;
